import { Link } from "gatsby";
import React from "react";
import "../components/layout/custom-css/link.css";

import Layout from "../components/layout";
const Kapcsolat = () => (
  <Layout>
    <div id="app">
      <div className="container content-wrap">
        <div className="row my-3">
          <div className="col">
            <h1>Kapcsolat</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.596094692269!2d19.02954341604837!3d47.49778130348141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc23824b43fb%3A0xeafdab69e9defdd1!2sSzelence+Caf%C3%A9+Health+Food+Restaurant!5e0!3m2!1shu!2shu!4v1515009209596"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              title={"Map"}
            ></iframe>
          </div>

          <div className="col-lg-4 col-md-12">
            <h3>Elérhetőség</h3>
            <ul className="list-unstyled">
              <li>Cím: Budapest 1013 Pauler utca 8.</li>
              <li>
                Telefon:{" "}
                <a className="linkDark" href="tel:+36308759079">
                  +36 30 875 90 79
                </a>
              </li>
              <li>
                E-mail:{" "}
                <a
                  className="linkDark"
                  href="mailto:info@szelencecafe.hu?Subject=Info"
                >
                  info@szelencecafe.hu
                </a>
              </li>
            </ul>
            <h3>Nyitvatartás</h3>
            <ul className="list-unstyled">
              <li>Kedd - Péntek: 9:00 - 16:00</li>
              <li>Szombat - Vasárnap - Hétfő: zárva</li>
            </ul>

            <h3>Tájékoztató</h3>
            <ul className="list-unstyled">
              <li>
                <Link to="cookie">Cookie tájékoztató</Link>
              </li>
              <li>
                <Link to="adatvedelmi">Adatvédelmi tájékoztató</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const Head = () => <title>Szelence Café | Kapcsolat</title>;

export default Kapcsolat;
